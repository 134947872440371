import "./style.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { changeInfoTela } from "../../layout/actions";
import fetchApi from "../../../commons/fetch-api";

function AccreditedBanksStart() {
  const dispatch = useAppState()[1];
  const [provider, setProvider] = useState();

  const { providerId } = useParams();

  useEffect(() => {
    dispatch(
      changeInfoTela({
        title: "Obter Confiabilidade via Bancos Credenciados",
        breadcrumbs: [{ label: "Obter Confiabilidade via Bancos Credenciados" }]
      })
    );
    fetchApi(
      "/api/aquisicao-confiabilidade-bancos-credenciados/:providerId".replace(
        ":providerId",
        providerId
      ),
      { method: "GET" }
    ).then(setProvider);
  }, []);

  return <>{provider && <PreAuthorizationView provider={provider} />}</>;
}

export default AccreditedBanksStart;

function PreAuthorizationView(props) {
  return (
    <>
      <span className="page-title">
        Obter Confiabilidade via {props.provider.title}
      </span>
      <p>
        Utilize o Auto-atendimento do(a) {props.provider.title} e adquira maior
        confiabilidade à sua conta <strong>gov.br</strong>. Nós não teremos
        acesso aos seus dados bancários, você só precisa autorizar nosso acesso.
      </p>
      <p>
        Para obter o nível de confiabilidade via {props.provider.title}, clique
        em <strong>Ir para o(a) {props.provider.title}</strong> abaixo.
      </p>
      <div className="action-group">
        <div className="action-group-left">
          <Link className="action-tertiary" to="/">
            Voltar
          </Link>
        </div>
        <div>
          <button
            className="action-primary"
            onClick={() =>
              window.location.assign(props.provider.authorizationUrl)
            }
          >
            Ir para o(a) {props.provider.title}
          </button>
        </div>
      </div>
    </>
  );
}
