import React from "react";
import { Link } from "react-router-dom";
import { faAdjust, faDeaf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "./Avatar";

function Header() {
  return (
    <header>
      <a href="https://www.gov.br">
        <img
          src={`${process.env.REACT_APP_URL_ASSETS}/images/govbr.png`}
          alt="Logomarca GovBR"
        />
      </a>
      <div id="acessibilidade">
        <span>
          <Link to="#" onClick={() => window.toggleContrast()}>
            <FontAwesomeIcon icon={faAdjust} />
            <span>Alto Contraste</span>
          </Link>
        </span>
        <span>
          <Link to="//www.vlibras.gov.br" target="_BLANK">
            <FontAwesomeIcon icon={faDeaf} />
            <span>VLibras</span>
          </Link>
        </span>
        {/*<Servicos />*/}
        <Avatar />
      </div>
    </header>
  );
}

export default Header;
