import React, { useEffect, useRef } from 'react'
import { useAppState } from '../AppContext'
import { dropdownMenuAvatar, dropdownMenuServico } from './actions'
import { logoutUser } from '../../commons/logged-user/actions'

function useOutsideAlerter(ref) {
  const [state, dispatch] = useAppState()
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dropdownMenuAvatar(false))
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function Avatar() {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const [state, dispatch] = useAppState()

  const dropdown = () => {
    dispatch(dropdownMenuAvatar(!state.layout.avatar))
    dispatch(dropdownMenuServico(false))
  }

  return (
    <div ref={wrapperRef} className="dropdown">
      <img
        alt="Foto"
        className="avatar-pic"
        onClick={() => dropdown()}
        src={`${state.logged_user.photoBase64}`}
        onError={e => {
          e.target.src = `${process.env.REACT_APP_URL_ASSETS}/images/foto-upload.png`
        }}
      />
      <div className={`dropdown-content ${state.layout.avatar ? 'show' : ''}`}>
        <img src={`${state.logged_user.photoBase64}`} alt="Nome do Cidadão" onError={e => { e.target.src = `${process.env.REACT_APP_URL_ASSETS}/images/foto-upload.png`  }} />
        <p>
          <b>{state.logged_user.name}</b>
        </p>
        <p>{state.logged_user.email}</p>
        <hr />
        <button
          className="action-secondary"
          onClick={() => dispatch(logoutUser())}
        >
          Sair
        </button>
      </div>
    </div>
  )
}

export default Avatar
