import "./style.css";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppState, useQuery } from "../AppContext";
import { changeMessageDelay } from "../layout/actions";
import { changeInfoTela } from "../layout/actions";
import { changeFormData } from "./actions";
import { reliabilityAcquired } from "./actions";
import ModalHowToObtainReliability from "./ModalHowToObtainReliability";
import ModalAcquiredReliabilities from "./ModalAcquiredReliabilities";
import {
  retrieveUserReliabilities,
  retrieveUserReliabilitiesByClient,
  changeReliabilityInfo,
  viewUserReliabilitiesByLevel,
  setModalAcquiredReliabilitiesVisibility,
  setModalHowToObtainReliabilityVisibility
} from "./actions";

const componentDidMount = (
  dispatch,
  state,
  qpClientId,
  qpRel,
  qpCategories,
  qpLevels
) => () => {
  dispatch(changeInfoTela({ title: "Início" }));
  if (qpClientId) {
    dispatch(
      retrieveUserReliabilitiesByClient(
        qpClientId,
        qpRel,
        qpCategories,
        qpLevels
      )
    );
    sessionStorage.setItem("clientUrlReliability", window.location.href);
  } else {
    if (sessionStorage.getItem("clientUrlReliability")) {
      window.location.href = sessionStorage.getItem("clientUrlReliability");
    } else {
      dispatch(retrieveUserReliabilities());
    }
  }
};

function Home() {
  const [state, dispatch] = useAppState();
  const [{ reliabilities }] = useAppState();

  const paramObj = useQuery();
  const qpReliabilities = paramObj.get("confiabilidades");
  const qpLevels = paramObj.get("niveis");
  const qpCategories = paramObj.get("categorias");
  const qpClientId = paramObj.get("client_id");

  const lvls = reliabilities.reliabilities;
  useEffect(
    componentDidMount(
      dispatch,
      state,
      qpClientId,
      qpReliabilities,
      qpCategories,
      qpLevels
    ),
    []
  );

  const client = reliabilities.client;

  const [{ formData }] = useAppState();
  if (formData.reliabilityAcquired) {
    dispatch(changeFormData({ ...formData, reliabilityAcquired: false }));
  }

  let apiBaseUrl =
    "https://" + window.location.hostname + "/api/reliabilities/";
  let history = useHistory();

  const viewUserReliabilities = levelId => {
    dispatch(setModalAcquiredReliabilitiesVisibility(true));
    dispatch(viewUserReliabilitiesByLevel({ levelId }));
  };

  const obtainReliability = rel => {
    if (rel.reliability.urlAquisicao !== undefined) {
      history.push(rel.reliability.urlAquisicao);
    } else {
      openHowToObtainReliabilityModal(rel.reliability);
    }
  };

  const openHowToObtainReliabilityModal = reliability => {
    dispatch(setModalHowToObtainReliabilityVisibility(true));
    dispatch(changeReliabilityInfo({ reliability }));
  };

  const goToClientPage = () => {
    if (state.layout.message.type === undefined) {
      dispatch(
        changeMessageDelay({
          type: "SUCCESS",
          text:
            "Você adquiriu as confiabilidades necessárias. Aguarde, em instantes você será redirecionado de volta para " +
            client.name
        })
      );
    }
    sessionStorage.removeItem("clientUrlReliability");
    setTimeout(() => {
      window.location.href = client.homePage;
    }, 10000);
  };

  return (
    <div className="home__welcome-panel">
      {reliabilities && (
        <>
          <span className="page-title ng-scope">Selos de Confiabilidade</span>
          {lvls && lvls.filter(l => l.anyAcquired).length > 0 && (
            <h4>Você já possui as seguintes confiabilidades:</h4>
          )}
          <div className="panel-reliability-card-inactive">
            {lvls &&
              lvls
                .filter(l => l.anyAcquired)
                .map(l => (
                  <div
                    className="reliability-card-inactive"
                    key={l.level.id}
                    onClick={() => viewUserReliabilities(l.level.id)}
                  >
                    <h4>
                      <strong>{l.level.titulo}</strong>
                    </h4>
                    {l.categories &&
                      l.categories
                        .filter(cat => cat.anyAcquired)
                        .map(cat => (
                          <div
                            className="acquiredCategory"
                            key={cat.category.id}
                          >
                            {cat.reliabilities &&
                              cat.reliabilities
                                .filter(rel => rel.acquired)
                                .map(rel => (
                                  <img
                                    key={rel.reliability.id}
                                    src={
                                      apiBaseUrl + rel.reliability.id + "/image"
                                    }
                                  ></img>
                                ))}
                            <p>{cat.category.titulo}</p>
                          </div>
                        ))}
                  </div>
                ))}
          </div>

          {lvls && lvls.filter(l => !l.allAcquired).length > 0 && client ? (
            <h4>
              Para prosseguir para{" "}
              <a target="_blank" href={client.homePage}>
                {client.name}{" "}
              </a>{" "}
              é necessário que você adquira as seguintes confiabilidades:
            </h4>
          ) : (
            <h4>Adquira novas confiabilidades do gov.br</h4>
          )}
          {lvls &&
            lvls
              .filter(l => !l.allAcquired)
              .map(l => (
                <fieldset key={l.level.id}>
                  <h3 key={l.level.id}>{l.level.titulo} </h3>
                  {l.categories &&
                    l.categories
                      .filter(cat => !cat.allAcquired)
                      .map(cat => (
                        <div className="reliability-card" key={cat.category.id}>
                          <h4>{cat.category.titulo} </h4>
                          <p>{cat.category.descricao}</p>
                          {cat.reliabilities &&
                            cat.reliabilities
                              .filter(rel => !rel.acquired)
                              .map(rel => (
                                <div
                                  className="reliability-item"
                                  key={rel.reliability.id}
                                  onClick={() => obtainReliability(rel)}
                                >
                                  <img
                                    src={
                                      apiBaseUrl + rel.reliability.id + "/image"
                                    }
                                    style={{ height: "2.5em" }}
                                  ></img>
                                  <p>
                                    <strong>{rel.reliability.titulo}</strong>
                                  </p>
                                </div>
                              ))}
                        </div>
                      ))}
                </fieldset>
              ))}
          <ModalHowToObtainReliability />
          <ModalAcquiredReliabilities />
        </>
      )}
      {reliabilities.redirect && goToClientPage()}
    </div>
  );
}

export default Home;
