
import './style.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppState, useQuery } from '../../AppContext'
import { changeInfoTela } from '../../layout/actions'
import { createSocialSecurityToken } from './actions'
import { changeFormData } from '../../home/actions'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({ 
      title: 'Obter Confiabilidade por meio de Validação de Informações da Previdência Social', 
      breadcrumbs: [{ label: 'Obter Confiabilidade por meio de Validação de Informações da Previdência Social' }] })
  )
  return () => {
    dispatch(changeFormData({questions: [], formData:{}, biovalid: {}}))
  }
}

function SocialSecurityKba() {
  const [state, dispatch] = useAppState()
  
  useEffect(componentDidMount(dispatch, state), [])

  const redirectToSocialSecutiryService = () =>{
    dispatch(createSocialSecurityToken())
  }


  return (
    <>
      <span className="page-title">Obter Confiabilidade por meio de Validação de Informações da Previdência Social</span>
      <p>Para obter o nível de confiabilidade por meio de Validação de Informações da Previdência Social, clique em <strong>Responder Questionário</strong> abaixo.</p>

      <div className="action-group">
        <div className="action-group-left">
            <Link className="action-tertiary" to="/" >Voltar</Link>
        </div>
        <div>
          <button className="action-primary" type="submit" onClick={()=>redirectToSocialSecutiryService()} >Responder questionário</button>
        </div>
      </div>
    </>
  )
}

export default SocialSecurityKba
