import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { changeInfoTela } from '../../../../src/components/layout/actions'
import { useAppState } from '../../AppContext'
import { putFederalEmployeePassword } from './actions'
import { changeFormData } from '../../home/actions'
import { changeMessageDelay } from '../../layout/actions'

const componentDidMount = (dispatch, state) => () => {
  dispatch(
    changeInfoTela({ title: 'Obter Confiabilidade de Servidor Público', breadcrumbs: [{ label: 'Obter Confiabilidade de Servidor Público' }] })
  )
  return () => {
    dispatch(changeFormData({questions: [], formData:{}, biovalid: {} }))
  }
}

function FederalEmployee() {
  const [state, dispatch] = useAppState()
  const { formData } = state
  useEffect(componentDidMount(dispatch, state), [])
  const validate = password =>{
    if(password === undefined || password.length === 0){
      dispatch(
        changeMessageDelay({ type: 'ERROR', text: 'Preencha o campo "senha".' })
      )
      return;
    }
    dispatch(putFederalEmployeePassword(password))
    dispatch(componentDidMount(dispatch, state), [])
  }
  return (
    <>
      <span className="page-title">Obter Confiabilidade de Servidor Público</span>
      <p>Para obter a confiabilidade como Servidor Público é necessário que informe a senha de servidor do sistema SIGEPE.</p>
      <label htmlFor="password">Digite a senha do SIGEPE (SIGAC)</label>
      <input
        value={formData.password}
        type="password"
        placeholder="Digite a sua senha do Sigepe (campo obrigatório)"
        onChange={e => {
          dispatch(
            changeFormData(
              { ...formData, password: e.target.value }
            )
          )
        }}
        autoFocus={true}
      />
      <div className="action-group">
        <div className="action-group-left">
            <Link className="action-tertiary" to="/" >Voltar</Link>
        </div>
        <div >
            <button className="action-primary" type="submit" onClick={()=>validate(formData.password)}>Validar Senha</button>
        </div>
      </div>
    </>
  )
}

export default FederalEmployee
