import "./style.css";

import React, { useState } from "react";
import Modal from "react-modal";
import { useAppState } from "../AppContext";
import { setModalAcquiredReliabilitiesVisibility } from "./actions";
import { useHistory } from "react-router-dom";

Modal.setAppElement("#root");

function ModalAcquiredReliabilities() {
  let apiBaseUrl =
    "https://" + window.location.hostname + "/api/reliabilities/";

  const [state, dispatch] = useAppState();
  const [{ reliabilities }] = useAppState();
  const level = state.userReliabilitiesByLevel.level;
  let acquiredLevel = {};
  let acquiredCategories = [];
  let acquiredReliabilities = [];
  let history = useHistory();
  if (level) {
    acquiredLevel = reliabilities.reliabilities.filter(
      l => l.level.id === level.levelId
    )[0];
    acquiredCategories = acquiredLevel.categories.filter(c => c.anyAcquired);
    var reliabilitiesArray = acquiredCategories.map(c => c.reliabilities);
    for (var i = 0; i < reliabilitiesArray.length; i++) {
      reliabilitiesArray[i].map(r => acquiredReliabilities.push(r));
    }
  }

  const closeModal = () => {
    dispatch(setModalAcquiredReliabilitiesVisibility(false));
  };

  const updateReliability = rel => {
    history.push(rel.reliability.urlAquisicao + "?acao=atualizar");
  };

  return (
    <Modal
      isOpen={state.modal_reliability_visibility.acquired_reliability}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal"
    >
      {acquiredCategories &&
        acquiredCategories.map(cat => (
          <span key={cat.category.id}>
            <table>
              <thead>
                <th colSpan="2" style={{ width: "75%" }}>
                  {cat.category.titulo}
                </th>
                <th style={{ width: "25%" }}>Validado em</th>
                {(cat.category.id === "801" ||
                  cat.category.id === "301") && (
                  <th style={{ width: "25%" }}>Ação</th>
                )}
                <th></th>
              </thead>
              <tbody>
                {acquiredReliabilities
                  .filter(
                    r =>
                      r.acquired && r.reliability.categoria === cat.category.id
                  )
                  .map(rel => (
                    <tr key={rel.reliability.id}>
                      <td>
                        <img
                          src={apiBaseUrl + rel.reliability.id + "/image"}
                        ></img>{" "}
                      </td>
                      <td>{rel.reliability.titulo} </td>
                      <td data-label="Validado em">{rel.updateDate}</td>
                      {(rel.reliability.id == "801" ||
                        rel.reliability.id == "701") && (
                        <button
                          className="action-primary"
                          onClick={() => updateReliability(rel)}
                        >
                          Atualizar
                        </button>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </span>
        ))}
      <footer>
        <button className="action-secondary" onClick={closeModal}>
          Fechar
        </button>
      </footer>
    </Modal>
  );
}

export default ModalAcquiredReliabilities;
