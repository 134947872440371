import "./style.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppState, useQuery } from "../../AppContext";
import { changeInfoTela } from "../../layout/actions";
import { changeFormData } from "../../home/actions";
const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({
      title: "Obter Confiabilidade por Certificado Digital",
      breadcrumbs: [{ label: "Obter Confiabilidade por Certificado Digital" }]
    })
  );
  return () => {
    dispatch(changeFormData({ questions: [], formData: {}, biovalid: {} }));
  };
};

function Certificate() {
  const [state, dispatch] = useAppState();

  const paramObj = useQuery();
  const qpAcao = paramObj.get("acao");

  const isUpdateCertificateReliability = qpAcao === "atualizar";

  useEffect(componentDidMount(dispatch, state), []);

  const avancar = () => {
    window.location.hostname.startsWith("certificado.")
      ? (window.location.href = `${window.location.protocol}//${window.location.hostname}/ler-certificado-digital?accountId=${state.logged_user.id}`)
      : (window.location.href = `${window.location.protocol}//certificado.${window.location.hostname}/ler-certificado-digital?accountId=${state.logged_user.id}`);
  };

  return (
    <>
      <span className="page-title">
        {!isUpdateCertificateReliability ? "Obter" : "Atualizar"} Confiabilidade
        por Certificado Digital
      </span>

      <p>
        O certificado digital ICP-Brasil funciona como uma identidade virtual
        que permite a identificação segura e inequívoca do autor de uma mensagem
        ou transação feita em meios eletrônicos, como a web. Esse documento
        eletrônico é gerado e assinado por uma terceira parte confiável, ou
        seja, uma Autoridade Certificadora (AC) que, seguindo regras
        estabelecidas pelo Comitê Gestor da ICP-Brasil, associa uma entidade
        (pessoa, processo, servidor) a um par de chaves criptográficas. Os
        certificados contém os dados de seu titular conforme detalhado na
        Política de Segurança de cada Autoridade Certificadora.
      </p>
      <p>
        <a href="https://www.iti.gov.br/certificado-digital" target="_blank">
          Saiba Mais
        </a>
        &nbsp;|&nbsp;
        <a
          href="https://www.iti.gov.br/certificado-digital/58-certificado-digital/87-como-obter"
          target="_blank"
          rel="noopener noreferrer"
        >
          Como obter
        </a>
      </p>
      <div className="action-group">
        <div className="action-group-left">
          <Link className="action-tertiary" to="/">
            Voltar
          </Link>
        </div>
        <div>
          <button className="action-primary" onClick={avancar}>
            Ler Certificado Digital
          </button>
        </div>
      </div>
    </>
  );
}

export default Certificate;
