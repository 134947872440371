import './style.css'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { useAppState } from '../AppContext'
import {
  setModalHowToObtainReliabilityVisibility,
} from './actions'

Modal.setAppElement('#root')

function ModalHowToObtainReliability() {
  let apiBaseUrl = 'https://'+window.location.hostname+'/api/reliabilities/'

  const [state, dispatch] = useAppState()
  const reliabilityInfo = state.reliabilityInfo.info
  const rel = reliabilityInfo

  const closeModal = () => {
    dispatch(setModalHowToObtainReliabilityVisibility(false))
  }

  return (
    <Modal
      isOpen={state.modal_reliability_visibility.obtain_reliability}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal"
    >
      {rel &&
        <span key={rel.reliability.id}  >
          <img src={apiBaseUrl+(rel.reliability.id)+'/image'} ></img> 
          <p><strong>{rel.reliability.titulo}</strong></p>
          <p>{rel.reliability.descricao}</p>
          {rel.reliability.orientacoesHtml &&
            <div key={'div'+rel.reliability.id}>
              <p><strong>Orientações</strong></p>
              <p dangerouslySetInnerHTML={{__html:rel.reliability.orientacoesHtml}} />
            </div>
          }
          <footer>
              <button className="action-secondary" onClick={closeModal}>Fechar</button>
          </footer>
        </span>
      }
    </Modal>
  )
}

export default ModalHowToObtainReliability
