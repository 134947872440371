import "./style.css";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import QRCode from "qrcode.react";
import { useAppState, useQuery } from "../../AppContext";
import { changeInfoTela, changeMessageDelay } from "../../layout/actions";
import {
  createFacialBiometricsQrCode,
  validateLastDriverLicenseDate
} from "./actions";
import { changeFormData } from "../../home/actions";

const componentDidMount = (dispatch, state, title) => () => {
  dispatch(
    changeInfoTela({
      title: title,
      breadcrumbs: [{ label: title }]
    })
  );

  return () => {
    dispatch(changeFormData({ questions: [], formData: {}, biovalid: {} }));
  };
};

function GovbrMobileApp() {
  const [state, dispatch] = useAppState();
  const biovalidInfo = state.formData;
  const { formData } = state;
  const currentPath = window.location.pathname;
  const paramObj = useQuery();
  const qpAcao = paramObj.get("acao");
  const isUpdateTseReliability = qpAcao === "atualizar";

  var title =
    (!isUpdateTseReliability ? "Obter" : "Atualizar") +
    " Confiabilidade por Validação Facial " +
    (currentPath === "/ganharConfiabilidadeTseFacial" ? "(TSE)" : "(Denatran)");
  const isTseReliability = currentPath === "/ganharConfiabilidadeTseFacial";

  useEffect(componentDidMount(dispatch, state, title), []);

  const generateQrCode = () => {
    if (isTseReliability) {
      dispatch(createFacialBiometricsQrCode("701", currentPath));
    } else {
      dispatch(createFacialBiometricsQrCode("401", currentPath));
    }
  };

  const validateDate = () => {
    if (!formData.lastDriverLicenseIssueDate) {
      return;
    }
    if (formData.lastDriverLicenseIssueDate.length !== 10) {
      dispatch(
        changeMessageDelay({
          type: "ERROR",
          text: "Informe uma data válida."
        })
      );
      return;
    }
    dispatch(
      validateLastDriverLicenseDate(
        formData.lastDriverLicenseIssueDate,
        biovalidInfo.biovalid.biometricsValidationSource
      )
    );
  };

  return (
    <>
      <span className="page-title">{title}</span>
      {isTseReliability && (
        <p>
          A confiabilidade por Validação Facial do <strong>gov.br</strong> pode
          ser realizada desde que você possua dados biométricos cadastrados no
          Tribunal Superior Eleitoral e o aplicativo <strong>gov.br</strong>{" "}
          instalado em seu dispositivo móvel. Após a validação do QR Code você
          validará informações do seu título de eleitor.
        </p>
      )}
      {!isTseReliability && (
        <p>
          A confiabilidade por Validação Facial do <strong>gov.br</strong> pode
          ser realizada desde que você possua{" "}
          <strong>Carteira Nacional de Habilitação</strong> e o aplicativo{" "}
          <strong>gov.br</strong> instalado em seu dispositivo móvel. Após a
          validação do QR Code você validará informações da sua CNH.
        </p>
      )}
      {(!biovalidInfo ||
        !biovalidInfo.biovalid ||
        !biovalidInfo.biovalid.qrCode) && (
        <div key={"downloadAtStores"} className="card" style={{ width: "50%" }}>
          <p>
            Você pode baixar o aplicativo <b>gov.br</b> gratuitamente nas
            lojas de aplicativos:
          </p>
          <a
            href="https://play.google.com/store/apps/details?id=br.gov.meugovbr"
            target="_blank"
          >
            <img
              src={`${process.env.REACT_APP_URL_ASSETS}/images/disponivel-no-google-play.png`}
              alt="Android"
              style={{ height: "35px" }}
            />
          </a>
          <a
            href="https://apps.apple.com/br/app/meu-gov-br/id1506827551"
            target="_blank"
          >
            <img
              src={`${process.env.REACT_APP_URL_ASSETS}/images/disponivel-na-apple-store.png`}
              alt="iOS"
              style={{ height: "35px" }}
            />
          </a>
          <p>
            Se você já possui o aplicativo, abra-o e clique no botão{" "}
            <strong>Gerar QR Code</strong> abaixo.
          </p>
        </div>
      )}
      {biovalidInfo &&
        biovalidInfo.biovalid &&
        biovalidInfo.biovalid.qrCode &&
        !biovalidInfo.biovalid.biovalidValidated && (
          <div
            key={biovalidInfo.biovalid.pin}
            style={{ textAlign: "center", margin: "2em" }}
          >
            <QRCode value={biovalidInfo.biovalid.qrCode} />
          </div>
        )}
      {biovalidInfo && biovalidInfo.biovalid.biovalidValidated && (
        <div key={"infoValidated"}>
          <label htmlFor="input-date">Data da última emissão de sua CNH</label>
          <input
            type="text"
            id="input-date"
            maxLength={10}
            placeholder="dd/mm/aaaa"
            value={formData.lastDriverLicenseIssueDate}
            onChange={e => {
              let date;
              try {
                date = window.dateMask(e.target.value);
              } catch (e) {
                date = null;
              }
              dispatch(
                changeFormData({
                  ...formData,
                  lastDriverLicenseIssueDate: date == null ? "" : date
                })
              );
            }}
          />
          <p className="atencao">
            Atenção: você tem 2 tentativas no período de 24 horas
          </p>
        </div>
      )}
      <div className="action-group">
        <div className="action-group-left">
          <Link className="action-tertiary" to="/">
            Voltar
          </Link>
        </div>
        <div>
          {(!biovalidInfo ||
            !biovalidInfo.biovalid ||
            !biovalidInfo.biovalid.qrCode) && (
            <button
              key={"btn-generate"}
              className="action-primary"
              type="submit"
              onClick={generateQrCode}
            >
              Gerar QR Code
            </button>
          )}
          {biovalidInfo &&
            biovalidInfo.biovalid &&
            biovalidInfo.biovalid.biovalidValidated && (
              <button
                key={"btn-validate"}
                className="action-primary"
                type="submit"
                onClick={validateDate}
              >
                Validar
              </button>
            )}
        </div>
      </div>
    </>
  );
}

export default GovbrMobileApp;
