
import './style.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppState } from '../../AppContext'
import { changeInfoTela } from '../../layout/actions'
import { changeMessageDelay } from '../../layout/actions'
import { retrieveKbaQuestions, validateKbaAnswers} from './actions'
import { changeFormData } from '../../home/actions'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({ title: 'Obter Confiabilidade por Carrossel de Perguntas', breadcrumbs: [{ label: 'Obter Confiabilidade por Carrossel de Perguntas' }] })
  )
  dispatch(retrieveKbaQuestions())
  
  return () => {
    dispatch(changeFormData({questions: [], formData:{}, biovalid: {}}))
  }
}

function PersonInfoKba() {
  const [state, dispatch] = useAppState()
  
  const [answers, setAnswers] = useState([-1,-1,-1],[])
  const questions = state.formData.formData ? state.formData.formData.questions : []
  const token = state.formData.formData ? state.formData.formData.token : null
  useEffect(componentDidMount(dispatch, state), [])

  const handleAnswers = (answers) => {
    setAnswers(answers)
  }

  const setResp = (idAnswer) => {
    let respostas = answers.slice()
    respostas.splice(idAnswer.split('_')[1], 1, parseInt(idAnswer.split('_')[3]));
    handleAnswers(respostas)
  }

  const putAnswer = (answers, token) =>{
    if(answers && (answers[0] === -1 || answers[1] === -1 || answers[2] === -1)){
      dispatch(
        changeMessageDelay({ type: 'ERROR', text: 'Selecione um resposta para cada questão.' })
      )
      return;
    }
    dispatch(validateKbaAnswers(answers, token))
  }

  return (
    <>
      <span className="page-title">Obter Confiabilidade por Carrossel de Perguntas</span>
      {questions && questions.length > 0 &&
        <div id="carrossel">
        <p id="guidelines">Para obter o nível de confiabilidade por conformidade, responda às perguntas abaixo:</p>
          <ol>
            {questions.map((questionObj, questionIndex) => {
              return (
                <div key={"aquestion_" + questionIndex}>
                  <li key={"bquestion_" + questionIndex}>{questionObj.question}</li>
                  <div className="radio-toolbar">
                    {questionObj.answerChoices.map((choice, answersIndex) => (
                      <span key={"question_" + questionIndex + "_answers_" + answersIndex}>
                        <input type="radio" id={"question_" + questionIndex + "_answers_" + answersIndex}
                              name={"question_" + questionIndex} value={choice}
                              onChange={e => setResp(e.currentTarget.id)}/>
                        <label htmlFor={"question_" + questionIndex + "_answers_" + answersIndex}>{choice}</label>
                      </span>
                    ))}
                  </div>
                </div>
              )
            })}
          </ol>
        </div>
      }

      <div className="action-group">
        <div className="action-group-left">
            <Link className="action-tertiary" to="/" >Voltar</Link>
        </div>
        <div>
            {token && 
              <button className="action-primary" type="submit" onClick={()=>putAnswer(answers, token)}>Enviar Resposta</button>
            }
        </div>
      </div>
    </>
  )
}

export default PersonInfoKba
