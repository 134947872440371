import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import Message from "./Message.js";

import Breadcrumb from "./Breadcrumb.js";
import Home from "../home/Home";
import FederalEmployee from "../acquire-reliability/federal-employee/FederalEmployee";
import PersonInfoKba from "../acquire-reliability/person-info-kba/PersonInfoKba";
import SocialSecurityKba from "../acquire-reliability/social-security-kba/SocialSecurityKba";
import AccreditedBanksStart from "../acquire-reliability/accredited-banks/AccreditedBanksStart";
import Certificate from "../acquire-reliability/certificate/Certificate";
import GovbrMobileApp from "../acquire-reliability/govbr-mobile-app/GovbrMobileApp";
import CertificateValidation from "../acquire-reliability/certificate/CertificateValidation";
import SocialSecurityKbaRedirectEndpoint from "../acquire-reliability/social-security-kba/SocialSecurityKbaRedirectEndpoint";
import AccreditedBanksRedirectEndpoint from "../acquire-reliability/accredited-banks/AccreditedBanksRedirectEndpoint";

function Content() {
  return (
    <main>
      <Breadcrumb />
      <Message />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/ganharConfiabilidadePorServidorPublico">
          <FederalEmployee />
        </Route>
        <Route path="/ganharConfiabilidadePorConformidade">
          <PersonInfoKba />
        </Route>
        <Route path="/chamaKbaPrevidencia">
          <SocialSecurityKba />
        </Route>
        <Route path="/social-security-kba-redirect-endpoint">
          <SocialSecurityKbaRedirectEndpoint />
        </Route>
        <Route path="/aquisicao-confiabilidade-autenticacao-bancaria/:providerId">
          <AccreditedBanksStart />
        </Route>
        <Route path="/oauth2-redirection-endpoint/:providerId">
          <AccreditedBanksRedirectEndpoint />
        </Route>
        <Route path="/ganharConfiabilidadePorCertificadoDigital">
          <Certificate />
        </Route>
        <Route path="/ler-certificado-digital">
          <CertificateValidation />
        </Route>
        <Route path="/ganharConfiabilidadeTseFacial">
          <GovbrMobileApp />
        </Route>
        <Route path="/ganharConfiabilidadeBiovalidFacial">
          <GovbrMobileApp />
        </Route>
      </Switch>
      {/*<div id="marca-serpro">
        <img
          src="https://cdn.dsgovserprodesign.estaleiro.serpro.gov.br/design-system/1.2.0/images/produto-serpro.png"
          alt="Produto Serpro"
        />
      </div>*/}
    </main>
  );
}

export default Content;
