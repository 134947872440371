
import './style.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppState, useQuery } from '../../AppContext'
import { socialSecurityRedirectEndpoint } from './actions'
import { changeInfoTela } from '../../layout/actions'


const componentDidMount = (dispatch, state, token) => () => {
  dispatch(
    changeInfoTela({ 
      title: 'Obter Confiabilidade por meio de Validação de Informações da Previdência Social', 
      breadcrumbs: [{ label: 'Obter Confiabilidade por meio de Validação de Informações da Previdência Social' }] })
  )
  dispatch(socialSecurityRedirectEndpoint(token))
  
}

function SocialSecurityKbaRedirectEndpoint() {
  const [state, dispatch] = useAppState()
  const queryParams = useQuery()
  const token = queryParams.get("token")
  useEffect(componentDidMount(dispatch, state, token), [])
  return (
    <>
      <span className="page-title">Obter Confiabilidade por meio de Validação de Informações da Previdência Social</span>

      <div className="action-group">
        <div className="action-group-left">
            <Link className="action-tertiary" to="/" >Voltar</Link>
        </div>
      </div>
    </>
  )
}

export default SocialSecurityKbaRedirectEndpoint
