import { API_PATH } from "../../commons/constants";
import fetchApi from "../../commons/fetch-api";

export const CHANGE_RELIABILITIES_ACTION = "USER/CHANGE_RELIABILITIES_ACTION";
export const CHANGE_RELIABILITY_INFO = "USER/CHANGE_RELIABILITY_INFO";
export const VIEW_USERRELIABILITIES_BYLEVEL =
  "USER/VIEW_USERRELIABILITIES_BYLEVEL";
export const MODAL_ACQUIREDRELIABILITIES_VISIBILITY =
  "MODAL_ACQUIREDRELIABILITIES_VISIBILITY";
export const MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY =
  "MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY";
export const FORM_DATA = "FORM_DATA";
export const IS_RELIABILITY_ACQUIRED = "IS_RELIABILITY_ACQUIRED";

export const retrieveUserReliabilities = () => async (dispatch, state) => {
  var reliabilities = await fetchApi(API_PATH.endpoints.getReliabilities, {
    method: "GET"
  });
  dispatch(
    changeReliabilitiesData({
      ...state.reliabilities,
      reliabilities: reliabilities
    })
  );
};

export const retrieveUserReliabilitiesByClient = (
  param_clientId,
  param_reliabilities,
  param_categories,
  param_levels
) => async (dispatch, state) => {
  const formData = {
    param_clientId,
    param_reliabilities,
    param_categories,
    param_levels
  };
  const reliabilities = await fetchApi(API_PATH.endpoints.getReliabilities, {
    method: "POST",
    body: JSON.stringify(formData)
  });

  if (reliabilities) {
    dispatch(
      changeReliabilitiesData({
        ...state.reliabilities,
        reliabilities: reliabilities.lstLevel,
        client: reliabilities.client,
        redirect: reliabilities.redirectAppClient,
        messageClient: reliabilities.messageClient
      })
    );
  }
};

export const changeReliabilitiesData = reliabilities => ({
  type: CHANGE_RELIABILITIES_ACTION,
  reliabilities
});

export const changeReliabilityInfo = reliability => ({
  type: CHANGE_RELIABILITY_INFO,
  reliability
});

export const viewUserReliabilitiesByLevel = levelId => ({
  type: VIEW_USERRELIABILITIES_BYLEVEL,
  levelId
});

export const setModalAcquiredReliabilitiesVisibility = data => ({
  type: MODAL_ACQUIREDRELIABILITIES_VISIBILITY,
  data
});

export const setModalHowToObtainReliabilityVisibility = data => ({
  type: MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY,
  data
});

export const reliabilityAcquired = data => ({
  type: IS_RELIABILITY_ACQUIRED,
  data
});

export const changeFormData = data => ({
  type: FORM_DATA,
  data
});
