
import './style.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppState } from '../../AppContext'

const componentDidMount = dispatch => () => {
}

function CertificateValidation() {
  const [state, dispatch] = useAppState()

  function escapeCertificateDomain () {
    var host = window.location.hostname.replace('certificado.', '')
    window.location = 'https://'+host
  }

  useEffect(componentDidMount(dispatch, state), [])
  return (
    <>
      <span className="page-title">Obter Confiabilidade por Certificado Digital</span>
      <p>O certificado digital ICP-Brasil funciona como uma identidade virtual que permite a identificação segura e inequívoca do autor de uma mensagem ou transação feita em meios eletrônicos, como a web. Esse documento eletrônico é gerado e assinado por uma terceira parte confiável, ou seja, uma Autoridade Certificadora (AC) que, seguindo regras estabelecidas pelo Comitê Gestor da ICP-Brasil, associa uma entidade (pessoa, processo, servidor) a um par de chaves criptográficas. Os certificados contém os dados de seu titular conforme detalhado na Política de Segurança de cada Autoridade Certificadora.</p>
      <p>

      <a href="https://www.iti.gov.br/certificado-digital" target="_blank" >Saiba Mais</a>
      &nbsp;|&nbsp;
      <a href="https://www.iti.gov.br/certificado-digital/58-certificado-digital/87-como-obter" target="_blank" rel="noopener noreferrer">Como obter</a>
      </p>
      <div className="action-group">
        <div className="action-group-left">
            <Link className="action-tertiary" to="/" onClick={()=>escapeCertificateDomain()} >Voltar</Link>
        </div>
      </div>
    </>
  )
}

export default CertificateValidation




