import { API_PATH } from './constants'

let changeLoadingState = () => {}
let changeMessageDelay = () => {}

function getJson(response) {
  return response.json().catch(e => {
    return Promise.resolve({}) //Garante que sempre será retornado um JSON (mesmo que seja um JSON vazio)
  })
}

export const setDependenciesFunctions = props => {
  changeLoadingState = props.changeLoadingState
  changeMessageDelay = props.changeMessageDelay
}

const fetchApi = async (url, options, notJson, pathToStop) => {
  let data
  if(pathToStop && window.location.pathname !== pathToStop){
    data = {}
    data.stopCall = true
    return data
  }
  let xsrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  )
  if ((options.method === 'POST' || options.method === 'PUT') && !notJson) {
    options.headers = { 'Content-Type': 'application/json' }
  }
  if (options.headers) {
    options.headers = { ...options.headers, 'X-XSRF-TOKEN': xsrfToken }
  } else if (xsrfToken) {
    options.headers = { 'X-XSRF-TOKEN': xsrfToken }
  }

  changeLoadingState(true)
  const resp = await fetch(url, options)
  !notJson ? (data = await getJson(resp)) : (data = await resp.arrayBuffer())
  changeLoadingState(false)
  
  if (resp.status === 200 || resp.status === 201) {
    if(!notJson && !Object.keys(data).length){
      return true;
    }
    return data
  }
  if (data.errors && data.errors[0]) {
    if([ 
      'REQUEST_SESSION_MUSTEXISTS',
      'REQUEST_SESSION_MUSTBEVALID',
      'REQUEST_REALREMOTEADDRESS_MUSTMATCHSESSIONREMOTEADDRESS',
      'REQUEST_CSRF_MUSTMATCHSESSIONCSRF',
      'REQUEST_SESSION_MUSTBENOTEXPIRED',
      'REQUEST_SESSION_MUSTBENOTREVOKED',
      'REQUEST_SESSION_ACCESSTOKEN_MUSTBENOTEXPIRED',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_INVALIDREQUEST',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_INVALIDCLIENT',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_INVALIDGRANT',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_UNAUTHORIZEDCLIENT',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_UNSUPPORTEDGRANTTYPE',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_INVALIDSCOPE',
      'REQUEST_REFRESHTOKEN_RESPONSE_ERROR_EXTENSIONERROR',
      'REQUEST_SESSION_ACCESSTOKEN_MUSTBEVALID',
      'REQUEST_SESSION_IDTOKEN_MUSTBEVALID'
    ].includes(data.errors[0].code)) {
      window.location.href = API_PATH.endpoints.start;
      return;
    }
    if (!['BIOVALIDTOKEN_RESULT_MUSTBENOTNULL'].includes(data.errors[0].code)) {
      changeMessageDelay({ type: 'ERROR', text: data.errors[0].title })
    }
  }
}

export default fetchApi
